import React from 'react';
import T from 'prop-types';

import { demoPageUrl } from '../../../alchemy/alchemy-static-page-urls';
import SocialForm from './SocialForm';

const UserSocials = ({
  links,
  onForgotClick,
  showEmail,
}) => (
  <div className="user-auth-socials">
    <div className="user-auth-socials-forgot">
      {showEmail
        ? <a className="request-demo-link" href={demoPageUrl}>Don’t have an Apptopia account?&nbsp;<span className="text-underline">Request a Demo</span></a>
        : <button className="btn-link" onClick={onForgotClick}>Forgot Password?</button>
      }
    </div>
    <div className="user-auth-socials-list">
      <SocialForm
        link={links.google}
        formId="google-oauth2"
        socialName="Google"
        iconClass="google-img"
      />
      <SocialForm
        link={links.linkedin}
        formId="google-linkedin"
        socialName="LinkedIn"
        iconClass="linkedin-img"
      />
    </div>
  </div>
);

UserSocials.propTypes = {
  links: T.object.isRequired,
  onForgotClick: T.func.isRequired,
  showEmail: T.bool,
};

export default UserSocials;
